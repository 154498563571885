import { type ChangeEvent, useState } from 'react';

import type { ActionFunctionArgs, LoaderFunctionArgs, MetaFunction } from '@remix-run/node';
import { json, redirect } from '@remix-run/node';
import { Form, NavLink, useActionData, useNavigate, useSearchParams } from '@remix-run/react';

import { Close, FacebookOutlined, Google, LockOutlined } from '@mui/icons-material';
import { Avatar, Button, TextField, Typography } from '@mui/material';

import { CloseButton, Error } from '@shared/styled';

import { useToken } from '~/hooks/use-token';

import styles from '~/routes-styles/login.module.css';
import { createUserSession, getUserJWT } from '~/session.server';
import axios from '~/utils/axios';
import { safeRedirect } from '~/utils/cookies';

import classNames from 'classnames';
import md5 from 'md5';

function verifyLogin(email: any, password: any) {
  return axios
    .post('/users/authenticate', {
      username: email,
      password: md5(password),
    })
    .then((response) => `Bearer ${response.data.token}`);
}

export const meta: MetaFunction = () => {
  return [
    { title: 'Login - Dodgeball Hub' },
    { name: 'description', content: 'Find all the dodgeball you need by just looking through the world map!' },
    { property: 'og:image', content: 'https://www.dodgeballhub.com/og-img.png?version=1' },
    { property: 'og:title', content: 'Login - Dodgeball Hub' },
    { property: 'og:description', content: 'Find all the dodgeball you need by just looking through the world map!' },
    { property: 'og:url', content: 'https://www.dodgeballhub.com/login' },
    {
      tagName: 'link',
      rel: 'canonical',
      href: 'https://www.dodgeballhub.com/login',
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const userId = await getUserJWT(request);

  if (userId) {
    return redirect(new URL(request.url).searchParams.get('redirectTo') || '/');
  }
  return json({});
}

export async function action({ request }: ActionFunctionArgs) {
  const formData = await request.formData();
  const email = formData.get('email');
  const password = formData.get('password');
  const redirectTo = safeRedirect(formData.get('redirectTo'));

  // Perform form validation
  // For example, check the email is a valid email
  // Return the errors if there are any

  try {
    const token = await verifyLogin(email, password);
    return createUserSession({
      request,
      userJWT: token,
      redirectTo,
    });
  } catch (error: any) {
    console.error(error.response.data);
    return json({ errors: error.response.data });
  }
}

export default function LoginPage() {
  const data = useActionData<typeof action>();
  const { setTokenLoading } = useToken();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectTo = searchParams.get('redirectTo') || '/';

  const [state, setState] = useState({
    email: '',
    password: '',
    errors: {} as { email: string; password: string; message: string },
    loading: false,
  });
  const { errors } = data || { errors: {} };

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setState({
      ...state,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  return (
    <div className={styles.StyledLoginModal}>
      <div className={styles.Wrapper}>
        <Form onSubmit={() => setTokenLoading(true)} method="post" className={styles.Form} noValidate>
          <div className={styles.Paper}>
            <CloseButton type="button" onClick={() => navigate('/')}>
              <Close />
            </CloseButton>
            <Avatar className={styles.StyledAvatar}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Login
            </Typography>

            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Username / Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              helperText={errors.email}
              error={errors.email ? true : false}
              onChange={handleChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={errors.password}
              error={errors.password ? true : false}
              onChange={handleChange}
            />
            <input
              type="hidden"
              name="redirectTo"
              value={`${redirectTo}${redirectTo.includes('?') ? '&' : '?'}login-success=true`}
            />
            <NavLink to="/forgotten-password" className={classNames(styles.StyledLink, styles.alignRight)}>
              Forgot your password?
            </NavLink>
            {errors.message && <Error className={styles.CustomError}>{errors.message}</Error>}
            <Button
              className={styles.StyledButton}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={!state.email || !state.password}
            >
              Sign In
            </Button>

            <span className={styles.separator}>OR</span>
            <div className={styles.ssoButtons}>
              <button
                type="button"
                className={styles.facebookBtn}
                onClick={() => navigate(`/api/auth/facebook?redirectTo=${redirectTo}`)}
              >
                <FacebookOutlined />
                Login with Facebook
              </button>

              <button
                type="button"
                className={styles.googleBtn}
                onClick={() => navigate(`/api/auth/google?redirectTo=${redirectTo}`)}
              >
                <Google />
                Login with Google
              </button>
            </div>
            <NavLink to="/register" className={styles.StyledLink}>
              Don't have an account? <u>Sign Up</u>
            </NavLink>
          </div>
        </Form>
      </div>
    </div>
  );
}
